import React, { Component } from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { metrics } from 'react-metrics';
import Notice from 'components/Notice/Notice';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Auth from 'components/Shared/Auth';
import ZipContext from 'components/Shared/ZipContext';
import './Layout.scss';
import 'aos/dist/aos.css';

const LayoutOptions = {
  standard: { headerFooterType: 'common' },
  error: { headerFooterType: 'error' },
};

class Layout extends Component {
  constructor(props) {
    super(props);

    this.setZip = ({ zip, club }) => {
      this.setState(prevState => {
        return { zipInfo: { ...prevState.zipInfo, zip, club } };
      });
    };

    this.state = {
      showNotice: this.props.location.pathname === '/',
      mounted: false,
      zipInfo: {
        zip: null,
        club: null,
        setZip: this.setZip,
      },
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  // TODO: Create and reference header/footer version for after Auth flow here

  render() {
    const { children, location, type, logoLink } = this.props;
    const defaultOptions = { ...LayoutOptions[type] };

    const { mounted, showNotice } = this.state;
    const HeaderComponent = mounted && Auth.isAuthenticated() ? Header : Header;
    const FooterComponent = mounted && Auth.isAuthenticated() ? Footer : Footer;

    return (
      <div className={classnames({ 'show-notice': showNotice })}>
        <Notice onClose={() => this.setState({ showNotice: false })} />
        <div className="aaa-content">
          <ZipContext.Provider value={this.state.zipInfo}>
            {defaultOptions.headerFooterType && (
              <HeaderComponent
                type={defaultOptions.headerFooterType}
                logoLink={logoLink}
              />
            )}
            <div className="aaa-content-box">{children}</div>
          </ZipContext.Provider>
          {defaultOptions.headerFooterType && (
            <FooterComponent
              type={defaultOptions.headerFooterType}
              path={location.pathname}
            />
          )}
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideNavigation: PropTypes.bool,
};

const config = {
  debug: false,
  vendors: [
    {
      name: 'Segment',
      api: {
        pageView: (eventName, args) => {
          window.analytics.page(args);
        },
        track: (eventName, args) => {
          window.analytics.track(eventName, args);
        },
        identify: (userId, args) => {
          if (userId === 'anonymous') {
            window.analytics.identify(args);
          } else {
            window.analytics.identify(userId, args);
          }
        },
      },
    },
  ],
};

const options = {
  autoTrackPageView: true,
  useTrackBinding: false,
  attributePrefix: 'data-metrics',
  suppressTrackBindingWarning: true,
};

export default metrics(config, options)(Layout);
