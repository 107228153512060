import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import format from 'string-template';
import moment from 'moment-timezone';
import { MetricsElement } from 'react-metrics';
import { default as Link } from 'components/Shared/Link';

import './Footer.scss';

const FooterLinksRow = ({ links }) => {
  if (links) {
    return (
      <ul className="aaa-footer__links">
        {links.map(link => (
          <li
            className="aaa-footer__links"
            key={'footer_' + link.text.replace(' ', '_')}
          >
            <MetricsElement>
              <Link
                className="aaa-link-navigation-footer"
                target={link.newTab && '_blank'}
                to={link.url}
                data-metrics-event-name="footer_nav_click"
                data-metrics-label={link.text}
              >
                {link.text}
              </Link>
            </MetricsElement>
          </li>
        ))}
      </ul>
    );
  } else {
    return null;
  }
};

const LegalLinksRow = ({ links, location }) => {
  if (links) {
    return (
      <ul className="aaa-footer__legal-links">
        {links.map(link => (
          <li
            className="aaa-footer__legal-link-box"
            key={'footer_' + link.text.replace(' ', '_')}
          >
            <MetricsElement>
              <Link
                className="aaa-footer__legal-link"
                to={link.url}
                data-metrics-event-name="footer_legal_nav_click"
                data-metrics-label={link.text}
                onClick={(event) => {
                  if (location.pathname === link.url) {
                    event.preventDefault();
                    document.location.replace(link.url);
                  }
                }}
              >
                {link.text}
              </Link>
            </MetricsElement>
          </li>
        ))}
      </ul>
    );
  } else {
    return null;
  }
};

class FooterSource extends Component {
  constructor(props) {
    super(props);
    const { type } = props;
    this.footer = props.data.allFooterYaml.nodes.find(
      footer => footer.type === type
    );
  }

  render() {
    const footer = this.footer;
    return (
      <footer id="footer" className="aaa-footer">
        <div className="aaa-footer__content">
          <div className="aaa-footer__padding-box">
            <FooterLinksRow links={footer.navigationLinks} />
            <Location>
              {({ location, navigate }) => (
                <LegalLinksRow
                  links={footer.legalLinks}
                  location={location}
                  navigate={navigate}
                />
              )}
            </Location>
            <div
              className="aaa-footer__text"
              dangerouslySetInnerHTML={{
                __html: format(footer.infoText, {
                  instapageTerms: ReactDOMServer.renderToStaticMarkup(
                    <Link to={this.props.path}>{'Terms&Conditions'}</Link>
                  ),
                  year: moment().format('YYYY'),
                }),
              }}
            />
          </div>
        </div>
      </footer>
    );
  }
}

const Footer = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allFooterYaml {
          nodes {
            type
            navigationLinks {
              text
              url
            }
            infoText
            legalLinks {
              text
              url
            }
          }
        }
      }
    `}
    render={data => <FooterSource data={data} {...props} />}
  />
);

export default Footer;
