import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Location } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { VIEWPORT_DEFAULT } from 'components/Shared/Constants';

const dev = process.env.GATSBY_DEV;
const googleSiteVerification = process.env.GATSBY_GOOGLE_SITE_VERIFICATION;

const SEO = ({ lang, meta, title, titleTemplate, canonical }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  );
  const _titleTemplate = titleTemplate || `%s | ${site.siteMetadata.title}`;
  const metaTags = meta
    ? meta.map(item => ({ name: item.name, content: item.content }))
    : [];
  if (dev && dev === '1') {
    metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
  }
  if (googleSiteVerification) {
    metaTags.push({
      name: 'google-site-verification',
      content: googleSiteVerification,
    });
  }
  return (
    <Location>
      {({ location }) => (
        <Helmet
          defer={false}
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={_titleTemplate}
          meta={[
            {
              name: 'viewport',
              content: VIEWPORT_DEFAULT,
            },
            ...metaTags,
          ]}
          link={[
            {
              rel: 'canonical',
              href:
                canonical || `${site.siteMetadata.siteUrl}${location.pathname}`,
            },
          ]}
        />
      )}
    </Location>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
