import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

class AuthHandler {
  logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
  };

  getToken = () => {
    return localStorage.getItem('access_token');
  };

  isAuthenticated = () => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return moment().unix() < expiresAt;
  };

  setSession = token => {
    const decoded = jwtDecode(token);
    const expiresAt = decoded.exp;
    localStorage.setItem('access_token', token);
    localStorage.setItem('expires_at', expiresAt);
  };
}

const Auth = new AuthHandler();

export default Auth;
