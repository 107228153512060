import React from 'react';
import classnames from 'classnames';
import { default as Link, ArrowLink } from 'components/Shared/Link';

import './Button.scss';

export const LinkButtonPrimary = ({ ...props }) => (
  <Link
    {...props}
    className={classnames('aaa-button-primary', props.className)}
  />
);

export const LinkArrowButtonPrimary = ({ ...props }) => (
  <ArrowLink
    {...props}
    className={classnames('aaa-button-primary', props.className)}
  />
);

export const LinkButtonSecondary = ({ ...props }) => (
  <Link
    {...props}
    className={classnames('aaa-button-secondary', props.className)}
  />
);

export const LinkArrowButtonSecondary = ({ ...props }) => (
  <ArrowLink
    {...props}
    className={classnames('aaa-button-secondary', props.className)}
  />
);

export const PrimaryButton = ({ ...props }) => (
  <button
    {...props}
    className={classnames('aaa-button-primary', props.className)}
  />
);

export const SecondaryButton = ({ ...props }) => (
  <button
    {...props}
    className={classnames('aaa-button-secondary', props.className)}
  />
);

export const GetAfreeQuoteButton = ({ onClick, to, ...props }) => (
  <LinkButtonPrimary
    {...props}
    to={to === '#' ? null : to}
    onClick={to === '#' ? onClick : null}
  />
);
